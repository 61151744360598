<template>
  <v-card class="fill-height">
    <v-stepper
      :model-value="currentStep"
      class="the-navigation-landscape-stepper__stepper pa-2"
      flat
    >
      <v-stepper-header>
        <template
          v-for="(item, index) in itemsWithData"
          :key="index"
        >
          <v-divider
            v-if="index > 0"
          />
          <v-stepper-item
            :title="item.title"
            :value="item.value"
            :complete="currentStep > index + 1"
            class="the-navigation-landscape-stepper__stepper-item"
          />
        </template>
      </v-stepper-header>
    </v-stepper>

    <v-card-text
      class="pa-0"
      style="overflow-y: auto; height: calc(100% - 56px);"
    >
      <slot />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { mapGetters } from '~/assets/javascript/modules/vuex';

export default defineComponent({
  name: 'TheNavigationLandscapeStepper',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {
      ...mapGetters('viewsMenu', ['viewById']),
    };
  },
  computed: {
    currentStep() {
      return this.itemsWithData.findIndex(item => item.active) + 1;
    },
    itemsWithData() {
      return this.items.map((item, index) => ({
        ...item,
        value: index + 1,
        title: item.group_name || this.viewById(item.id)?.page_name,
        active: this.itemIsActive(item),
      }));
    },
  },
  methods: {
    itemIsActive(item) {
      return this.$route.params.viewId === item.id || item.items?.some(this.itemIsActive);
    },
  },
});
</script>
<style lang="scss">
.the-navigation-landscape-stepper__stepper {
  background-color: transparent;
}

.the-navigation-landscape-stepper__stepper-item {
  cursor: unset;
  padding-block: var(--z-s2);

  &:first-of-type {
    padding-left: var(--z-s2);
  }

  &:last-of-type {
    padding-right: var(--z-s2);
  }
}
</style>
