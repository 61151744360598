<template>
  <div
    v-if="type === 'stepper'"
    class="the-navigation-landscape"
    v-bind="$attrs"
  >
    <Stepper :items="items">
      <TheNavigationLandscape
        :navigation-group-path="nextNavigationGroupPath"
      >
        <slot />
      </TheNavigationLandscape>
    </Stepper>
  </div>
  <TheNavigationLandscape
    v-else-if="nextNavigationGroupPath.length > 0"
    :navigation-group-path="nextNavigationGroupPath"
    v-bind="$attrs"
  >
    <slot />
  </TheNavigationLandscape>
  <slot v-else/>
</template>

<script lang="ts">
import Stepper from './stepper.vue';

export default defineComponent({
  name: 'TheNavigationLandscape',
  components: {
    Stepper,
  },
  inheritAttrs: false,
  props: {
    navigationGroupPath: {
      type: Array,
      required: true,
    },
  },
  computed: {
    items() {
      if (this.navigationGroupPath.length === 0) return [];
      return this.navigationGroupPath[0].items;
    },
    type() {
      return this.navigationGroupPath[0]?.navigation_type;
    },
    nextNavigationGroupPath() {
      const [, ...rest] = this.navigationGroupPath;
      return rest;
    },
  },
});
</script>
<style lang="scss">
.the-navigation-landscape {
  height: 100%;
  max-height: calc(100dvh - (var(--z-dock-height) + var(--z-dock-margin) * 2) * 2);
}
</style>
